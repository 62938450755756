const faqs = [
  {
    question: "1. Requisitos migratorios para entrar a México",
    answer: (
      <div>
        <p>

- Pasaporte en vigencia: Es necesario contar con un pasaporte válido y en buen estado para poder viajar a México, sin especificar temporalidad. Recordá que algunas aerolíneas desean asegurarse de que sus pasajeros porten un pasaporte con al menos seis meses de validez, para garantizar sus servicios.  <br/> <br/>
- Datos personales e información adicional sobre tu estancia: Debes proporcionar información personal como tu nombre completo, fecha de nacimiento, dirección y número de teléfono, así como también detalles sobre tu estancia en México, como la duración de la estadía y el motivo del viaje. <br/> <br/>
- Razón del viaje: Si el motivo del viaje es turístico, debes presentar reservas de los hoteles y los pasajes internos necesarios para trasladarse entre las diferentes locaciones. <br/> <br/>
- Lugar de residencia o procedencia: Debes indicar tu lugar de residencia y su procedencia, es decir, el país de origen.  <br/> <br/>
- Domicilio y cantidad de días que vas a permanecer en el territorio mexicano: Es necesario proporcionar información sobre el domicilio en México y la cantidad de días que se va a permanecer en el país. Por lo que debés presentar un comprobante de pago del hotel o reserva hotelera realizada con tarjeta de crédito, que coincida con el plazo de permanencia que surja del pasaje aéreo. <br/> <br/>
- Carta de invitación: En caso de que un ciudadano mexicano o un extranjero con residencia legal en México te invite al país, debés presentar una carta de invitación con información detallada sobre ambas partes, incluyendo los datos personales, las fechas y propósito del viaje.<br/> <br/> - Profesión y actividades que realizarás en México: Debés proporcionar información sobre la profesión que se ejerce en el país de origen y las actividades que se realizarán en México. Es necesario presentar documentación que demuestre el arraigo con el país de origen o procedencia, como un recibo de sueldo o certificado de estudios. <br/> <br/> - Medios con los que piensas subsistir durante tu estancia en México: Es necesario acreditar una solvencia económica de USD 500 por persona, ampliándose a USD 50 por día de estadía que exceda de la semana. Si no contás con efectivo, deberás exhibir una tarjeta de crédito con un límite de gasto equivalente. <br/> <br/> - Medios con los que piensas subsistir durante tu estancia en México: Es necesario acreditar una solvencia económica de USD 500 por persona, ampliándose a USD 50 por día de estadía que exceda de la semana. Si no contás con efectivo, deberás exhibir una tarjeta de crédito con un límite de gasto equivalente.<br/> <br/> - Pasaje de regreso: Se debe contar con un pasaje de regreso al lugar de origen, con una fecha cierta de retorno que no supere los 180 días de estancia en México.


</p>
      </div>
    ),
  },
  {
    question: "2. ¿Los argentinos necesitan una visa para entrar a México? ",
    answer: (
      <div>
        <p>Los ciudadanos argentinos pueden ingresar a México sin necesidad de una visa por motivos de turismo por un período no superior a 180 días. El Instituto Nacional de Migración determinará el tiempo de estancia otorgado en su Forma Migratoria Múltiple al momento de su ingreso. Sin embargo, deben cumplir con la verificación migratoria y acreditar su condición de turista, lo que no permite ninguna actividad remunerada en México.

 </p> 

      </div>
    ),
  },
  {
    question: "3. ¿Es obligatorio el seguro de viaje en México? ",
    answer: (
      <div>
        <p>
        Aunque no es obligatorio, se recomienda contar con un seguro médico de viaje con cobertura vigente durante toda la estancia en México. Es probable que se solicite el comprobante de este seguro durante el ingreso al territorio mexicano.
 
        </p> 
      </div>
    ),
  },

];

export default faqs;
